@use '@angular/material' as mat;
@import 'stylesheets/variables';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/

@mixin recipe-custom-dialog($max-width: auto, $max-height: auto) {
  border-radius: 10px;
  text-align: center;
  max-width: $max-width;
  max-height: $max-height;
  background-color: $white,
}

.mat-mdc-form-field-error {
  color: $primary-red !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background-color: $primary-blue !important;
  height: 2px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: $primary-red !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
  color: $primary-red !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
mat-progress-bar {

  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  .mat-progress-bar-fill::after {
    background-color: $primary-red;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  .mat-progress-bar-buffer {
    background: $white;
  }

  &.mat-mdc-progress-bar {
    height: 3px;
  }
}

.recipe-mat-dialog .mat-mdc-dialog-container {
  @include recipe-custom-dialog(390px);
}

.recipe-ship-date-picker-modal .mat-mdc-dialog-container {
  @include recipe-custom-dialog(576px, calc(100vh - 110px));
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;

  @media screen and (max-width: $screen-small) {
    .primary-button {
      width: 200px !important;
    }
  }
}

.recipe-import-order-modal .mat-mdc-dialog-container {
  @include recipe-custom-dialog(910px, 581px);
  padding: 0;
  width: 80vw;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.recipe-import-guide-modal .mat-mdc-dialog-container,
.recipe-re-order-modal .mat-dialog-container {
  @include recipe-custom-dialog(910px, 581px);
  padding: 0;
  width: 90vw;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.recipe-edit-guide-toast-message {
  color: $white;
  margin-bottom: 64px !important;
}

.recipe-custom-guide-toast-message {
  color: $white;
  margin-bottom: 0 !important;
}

.recipe-actionable-toast-message .mat-mdc-snack-bar-action {
  color: $accent-yellow;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-menu-ripple {
  display: none !important;
}

.recipe-modal .mat-mdc-dialog-container {
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.cart-modal .mat-mdc-dialog-container,
.custom-guide-modal .mat-dialog-container,
.product-attributes-modal .mat-dialog-container {
  padding: 0;
  border-radius: 10px;
}

.generic-modal .mat-mdc-dialog-container {
  border-radius: 10px;
  text-align: center;
  padding: 48px 0; // override default padding of 24px
}

.mobile-search-modal .mat-mdc-dialog-container {
  background-color: $ui-light-gray;
}

.recipe-print-modal .mat-mdc-dialog-container {
  @include recipe-custom-dialog(606px, 360px);
  padding: 0;
  width: 606px;
  height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cdk-overlay-pane.mobile-search-modal {
  max-width: 100% !important;
}

.cdk-overlay-pane .product-attributes-modal {
  width: 100%;
  max-width: 604px !important;
}

.product-attributes-modal {
  .mat-mdc-dialog-container {
    padding: 0;
    border-radius: 10px;
  }
}

@media screen and (max-width: $screen-medium) {
  .recipe-modal .mat-mdc-dialog-container {
    padding: 22px 14px;
  }

  :not(::-webkit-scrollbar) {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $secondary-gray;
  }

  .cart-modal,
  .product-attributes-modal {
    max-width: 100% !important;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.category-anchor-select {
  label {
    display: none !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    display: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 11px;
  }
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar {
  background-color: $tab-active;
  height: 3px;
  font-size: em(13px);
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-group.mat-primary .mat-tab-label {
  text-transform: uppercase;
  font-weight: $medium-weight;
  letter-spacing: 1px;
  color: $tab-labels;

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  &:not(.mat-tab-disabled):focus {
    background-color: transparent;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-group.mat-primary .mat-tab-label-container {
  padding: 0;
}

.cdk-overlay-pane.inventory-add-item-dialog,
.cdk-overlay-pane.inventory-edit-item-dialog,
.cdk-overlay-pane.non-gfs-info-dialog {
  max-width: 100vw !important;
  overflow-y: auto;

  .mat-mdc-dialog-container {
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    padding: 0px;

    @media (max-width: $screen-medium) {
      border-radius: 0%;
    }
  }
}

.inventory-add-item-dialog,
.inventory-edit-item-dialog {
  .mat-mdc-dialog-container {
    .search-wrapper {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-label {
        padding-left: 37px;
        line-height: 32px;
        color: $primary-dark-gray;

        @media (max-width: $screen-medium) {
          font-size: 14px;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-underline {
        display: none;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-focused .mat-form-field-label {
      color: $primary-dark-gray !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
      border: 0;
      padding: 0;
    }
  }
}


.inventory-custom-edit-item-dialog .mat-mdc-dialog-container {
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 0px;

  position: relative;

  min-width: $screen-medium;
  max-width: $screen-medium;

  @media (max-width: $screen-medium) {
    border-radius: 0%;
  }

  .search-wrapper {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label {
      padding-left: 37px;
      line-height: 32px;
      color: $primary-dark-gray;

      @media (max-width: $screen-medium) {
        font-size: 14px;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      display: none;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-focused .mat-form-field-label {
    color: $primary-dark-gray !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    border: 0;
    padding: 0;
  }
}

.inventory-create-storage-area-dialog .mat-mdc-dialog-container {
  border-radius: 16px;
  box-shadow: none;
  padding: 24px;
}

.confirmation-item-dialog .mat-mdc-dialog-container {
  border-radius: 10px;
  box-shadow: none;
  padding: 0px;
  // padding-bottom: 45px;
  width: 604px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.confirmation-item-dialog .mat-form-field-ripple {
  background-color: $input-border;
}

.inventory-snack-bar {
  color: $white !important;
  padding: 19px 24px !important;
}

// spinner theme
$primary-red-base-palette: (
  50 : #f8e0e0,
  100 : #efb3b3,
  200 : #e48080,
  300 : #d94d4d,
  400 : #d02626,
  500 : $primary-red,
  600 : #c20000,
  700 : #bb0000,
  800 : #b40000,
  900 : #a70000,
  A100 : #ffd1d1,
  A200 : #ff9e9e,
  A400 : #ff6b6b,
  A700 : #ff5252,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000)
);
$primary-red-palette: mat.define-palette($primary-red-base-palette);
$primary-red-theme: mat.define-light-theme($primary-red-palette, $primary-red-palette);
@include mat.progress-spinner-theme($primary-red-theme);

.gu-mirror {
  box-sizing: border-box;
  border: solid 2px $drag-accent !important;
  border-bottom: solid 3px $drag-accent !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);

  .drag-handle,
  .draggable {
    cursor: grabbing !important;
  }
}

// high-performance display:none; helper
.gu-hide {
  left: -9999px !important;
}

// added to mirrorContainer (default = body) while dragging
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  cursor: grabbing !important;
}

// added to the source element while its mirror is dragged
.gu-transit {
  opacity: 0.4;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=40);

  .category-element {
    height: 100%;
    background-color: $drag-accent !important;

    >* {
      visibility: hidden;
    }
  }

  .list-card,
  .edit-custom-guide-category {
    background-color: $drag-accent !important;

    >* {
      height: 25px;
      visibility: hidden;
    }
  }
}

.spinner-left {
  left: 15px;
  position: absolute;
  top: 1px;
  height: 0;
}

.blur-value {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}