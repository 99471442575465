// You can add global styles to this file, and also import other style files
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'stylesheets/bootstrap.min.css';
@import 'stylesheets/bootstrap-theme.min.css';
@import 'stylesheets/variables';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

html {
  height: 100%;
}

// override for [hidden] being superceded by `display` rules https://stackoverflow.com/a/35578093/8037104
[hidden] {
  display: none !important;
}

body {
  background-color: $primary-background;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-weight: $regular-weight;
  font-size: $input-font-size;

  &:before {
    content: 'DESKTOP';
    display: none; // Prevent from displaying.
  }
  &.alternate {
    background-color: $primary-background;
  }
  @media (max-width: $screen-medium) {
    &:before {
      content: 'MOBILE';
    }
  }
    .warning-custom-tooltip {
      width: 28% !important;
      left: 53%;
      bottom: 25%;
    }
    .select-all-custom-tooltip{
      width: 50%;
      left:58%;
      top:80%;
    }
}

@media print {
  body {
    position: relative;
    padding-bottom: 0;
  }

  #_hj_feedback_container {
    display: none;
  }
}