@import './colors.scss';
@import './variables.scss';

// Styles defined on custom elements workaround ng-deep which is planned to be deprecated

// Draw outlines for WIP
.poc {
    margin: 5px;
    padding: 5px;
    border: 1px dashed black;
}

.loading-spinner-overlay {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
}

gfs-warning-icon {
    .wrapper {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        mat-icon {
            height: 20px;
            width: 20px;
            transform: translate(0, 25%) scale(0.9, 1);

            path {
                fill: $warning-yellow;
            }
        }
    }
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

.mat-menu-panel {
    max-width: 320px !important;
    min-height: 0px !important;
}

app-category-recipe-search-input,
gfs-item-search-bar {

    .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
        padding: 0.3em 0 0.5em 0;
    }

    .mat-mdc-form-field-icon-prefix>.mat-icon,
    .mat-mdc-form-field-icon-suffix>.mat-icon {
        padding: 10px;
    }

    div.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
        display: none;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined div.mat-mdc-form-field-infix {
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: unset;
    }

    .mat-form-field-wrapper {
        padding: 0;
    }
}

app-storage-area-select {
    .mat-mdc-form-field.storage-area-select-container {
        height: 60px;

        >.mat-mdc-text-field-wrapper {
            height: inherit;
        }
    }
}

gfs-infinite-scroll-list div.item-count-container,
app-storage-area-card div.item-count-container,
gfs-add-item-dialog {
    .mdc-text-field {
        padding: unset;
    }

    .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
        display: none;
    }

    .mat-mdc-form-field.item-count-unit div.mat-mdc-form-field-infix,
    mat-form-field.search-form div.mat-mdc-form-field-infix {
        padding: 0px !important;
        height: unset !important;
        min-height: unset;
    }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: unset !important;
}
.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input{
    color: unset !important;
}
.mat-mdc-standard-chip.mdc-evolution-chip--disabled { 
    opacity: unset !important;
}

.worksheet-import-spinner {
    margin-top: calc(50% - 130px);

    @media (max-width: $screen-medium) {
        margin-top: calc(50% - 90px);
    }
}

div.mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 10px;
}

gfs-inventory-migration-configuration-modal {
    .mat-dialog-container {
        padding: 0 !important;
        border-radius: 8px;

        @media (max-width: $screen-medium) {
            border-radius: 0;
        }
    }
}

.table-label {
    display: flex;
    width: 90%;
    margin: 42px 32px 30px 28px;

    font-family: Roboto, sans-serif;
    font-size: 28px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #212121;
}

.page-header-container {
    margin: 42px 32px 30px 28px;
    justify-content: space-between;
    display: flex;
    align-items: center;

    @media (max-width: $screen-medium) {
        margin-left: 5%;
        width: 90%;
        flex-direction: column;
    }

    h1 {
        font-size: 36px;
        line-height: 1.11;
        color: #212121;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .header {
        display: flex;
        align-content: center;
        align-items: center;
    }

    .search-container {
        position: relative;
        flex: 1;
        margin-left: 30px;
        margin-right: 20px;
    }

    .title-button-bar {
        display: flex;
        justify-content: center;

        @media (max-width: $screen-medium) {
            margin: 16px 0;
        }

        button {
            height: 41px;
        }
    }
}

.page-main-container {
    @media (max-width: $screen-medium) {
        margin: 0;
    }
}

gfs-assign-accounts {
    div.mat-mdc-form-field-infix {
        padding: 1em 0;
        height: unset !important;
        min-height: unset;
    }

    div.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
        display: none;
    }
}

gfs-customer-unit-searchbar {
    span.mat-form-field-label-wrapper label span {
        color: $secondary-gray;
    }
}

mat-form-field div div.mat-form-field-underline {
    background-color: $secondary-gray;
}

button.mat-button-base,
div.mat-menu-content button {

    background-color: $white;

    &:hover {
        background-color: $framework-accent;
    }
}

div.mat-autocomplete-panel,
div.mat-select-panel {
    box-shadow: 0 2px 1px -1px $framework-shadow-primary, 0 1px 1px $framework-shadow-secondary, 0 1px 3px $framework-shadow-tertiary;

    mat-option {
        background-color: $white;

        &:hover,
        .mat-active {
            background-color: $framework-accent;
        }

    }
}

body {
    background-color: $background-light-gray;
}

mat-dialog-container.mat-dialog-container {
    background-color: white;
}

button.mat-mdc-menu-item-highlighted:not([disabled]) {
    background-color: $framework-accent;
}

mat-checkbox {
    &.mat-mdc-checkbox-checked div.mdc-checkbox__background {
        background-color: $primary-blue !important;
        border-color: $primary-blue !important;
        color: $primary-blue !important;
    }
}

gfs-sub-header {
    a.mdc-tab.mat-mdc-tab-link {

        span.mdc-tab__text-label {
            color: $framework-black;
        }

        &.mdc-tab--active span.mdc-tab__text-label {
            color: $primary-red !important;
        }

        span.mdc-tab-indicator {
            display: none;
        }
    }
}

gfs-supplier-list {
    .mdc-list-item {
        padding-left: unset;
        padding-right: unset;
        flex-direction: column;
    }
}

app-storage-area-list {
    .mdc-list-item {
        padding-left: unset;
        padding-right: unset;

        >span {
            overflow: unset;
        }
    }

}

app-recipe-pricing-panel {
    mat-form-field.mat-mdc-form-field {

        div.mat-mdc-form-field-infix {
            padding-top: 0.2em !important;
            padding-bottom: 0.2em !important;
            min-height: unset;
        }

        div.mat-mdc-text-field-wrapper {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

app-edit-ingredient-card-v2 {
    button.mat-warn {
        color: $primary-red !important;
    }
}

app-create-recipe2 {
    div.new-recipe-info-panel {
        div.mat-mdc-form-field-infix {
            width: inherit !important;
        }
    }
}

mat-table {
    margin: 42px 32px 30px 28px;

    width: 90%;
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1),
        0 0 4px 0 rgba(0, 0, 0, 0.27);


    mat-header-row,
    mat-row {
        &.mat-mdc-row {
            @media (max-width: $screen-medium) {
                border-bottom: solid 1px $primary-light-gray;
            }
        }
    }

    &.mat-mdc-table.mdc-data-table__table {
        min-width: unset;
    }

    .mat-mdc-header-cell {
        background: $background-light-gray !important;
        font-family: Roboto, sans-serif;
        font-size: 11px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.2px;
        color: $primary-dark-gray;
        text-transform: uppercase;
    }

    .mdc-data-table__cell {
        padding: 0;

        @media (max-width: $screen-medium) {
            border-bottom-style: none;
        }
    }

    .mat-mdc-cell {
        font-weight: 500;
        color: $primary-dark-gray;

        .mat-icon-button {
            background-color: transparent;
        }
    }
}

mat-tooltip-component {
    div.mdc-tooltip.info-tooltip {
        width: unset;
        margin: 0;
        align-items: center;
    }

    div.mdc-tooltip__surface {
        max-width: unset;
    }
}

mat-radio-button {
    &.mat-mdc-radio-checked div.mdc-radio__background {

        div.mdc-radio__inner-circle,
        div.mdc-radio__outer-circle {
            border-color: $primary-blue !important;
        }
    }
}

mat-chip-option {
    span.mat-mdc-chip-action-label {
        display: contents;
    }
}

.mat-mdc-paginator-icon {
    fill: unset !important;
}

.mat-mdc-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    button.mat-mdc-menu-item {
        flex-direction: row;

        span {
            font-size: 14px !important;
        }
    }
}

button.mat-mdc-button:not(:disabled) {
    color: unset;
}

.mdc-button {
    letter-spacing: unset !important;
}

gfs-edit-gfs-item,
gfs-add-custom-item {
    mat-form-field {
        margin: 0 16px calc(38px - .45em) 16px;

        @media (max-width: $screen-medium) {
            margin: 0 24px 24px 24px;
        }
    }

    .mat-expansion-panel.custom-item-panel.item-info-panel {
        mat-form-field {
            &.item-name-form {
                width: 692px !important;
                margin-top: calc(24px + .8em);

                @media (max-width: $screen-medium) {
                    width: 100% !important;
                }
            }

            &.item-info-form {
                width: 330px !important;

                @media (max-width: $screen-medium) {
                    width: 100% !important;
                }
            }

            .item-storage-area-form,
            .item-category-form {

                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
                &>.mat-form-field-wrapper {
                    margin-bottom: calc(38px - 1.25em);
                }
            }
        }
    }

    .mat-expansion-panel.edit-item-panel {
        .mat-form-field {
            width: 330px;
        }
    }

    .mat-expansion-panel.custom-item-panel.purchase-unit-panel {
        div.form-group-wrapper {
            align-items: flex-start;
        }

        #not-priced-by-weight-section>div {
            padding-top: 35px;
        }

        mat-form-field {
            margin-right: 0;
            height: 60px;

            >div {
                padding-left: 0;
                padding-right: 0;
            }

            &.purchase-unit-form div {
                width: 100px;
            }

            &.catch-weight-price-form {
                span {
                    padding-top: 17px;
                }

                div {
                    width: 80px;
                }
            }

            &.net-weight-form div {
                width: 100px;
            }

            &.catch-weight-unit-form div {
                width: 132px;
            }
        }
    }
}

gfs-unit-of-measure-form {
    mat-form-field {
        >div {
            padding-left: 0;
            padding-right: 0;
        }

        &.custom-unit-measure-form-field div,
        &.unit-measure-form-field div {
            width: 320px;
        }

        &.parent-unit-form-field div,
        &.custom-unit-measure-qty-form-field div,
        &.unit-measure-qty-form-field div {
            width: 164px;
        }
    }

    div.unit-measure-form-field-wrapper {
        button.delete-button {
            margin: unset;
        }
    }
}

gfs-counting-unit-form {
    mat-form-field {
        >div {
            padding-left: 0;
            padding-right: 0;
        }

        &.counting-unit-form-field div {
            width: 320px;
        }

        &.unit-qty-form-field div,
        &.parent-unit-form-field div {
            width: 164px;
        }
    }

    div.custom-unit-form-field-wrapper {
        button.delete-button {
            margin: unset;
        }
    }
}