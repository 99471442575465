// SASS variables
// Palette
$primary-red: #c80000;
$primary-dark-gray: #505050;
$primary-gray: #767676;
$primary-light-gray: #c8c8c8;
$primary-light-yellow: #fcc11c;
$ui-light-gray: #f3f3f3;
$ui-lighter-gray: #fafafa;
$secondary-gray: #888b8d;
$secondary-orange: #dc582a;
$secondary-light-red: #da4a4a;
$secondary-dark-red: #8a2a2b;
$accent-green: #58813d;
$accent-purple: #595478;
$accent-teal: #4e87a0;
$accent-yellow: #eaaa00;
$accent-medium-yellow: #f1cb6e;
$accent-light-yellow: #fcf6e5;
$accent-orange: #fcdb81;
$primary-blue: #2b5a87;
$secondary-blue: #407aa0;
$accent-gray: #d1d3d5;
$white: #ffffff;
$focus-outline: #4d90fe;
$secondary-light-gray: #fbfbfb;
$secondary-lighter-gray: #eeeeee;
$line-light-gray: #dcdcdc;
$gray: #808080;
$blue-select: #dae2ea;
$flag-red: #a93f1a;
$flag-green: #245007;
$flag-yellow: #d87e00;
$flag-blue: #12304f;
$flag-red-background: #f9e4dc;
$flag-green-background: #b9d6a6;
$flag-yellow-background: #f8f0cb;
$flag-blue-background: rgba(#9cc0e9, 0.66);
$flag-light-green-background: rgba(189, 255, 215, 0.5);
$black: #000000;
$secondary-black: #212121;
$hover-filter-gray: #e9e9e9;
$flag-light-green: #094220;
$hover-menu-item: rgba(43, 90, 135, 0.1);
$focus-menu-item: rgba(43, 90, 135, 0.04);
$material-tabs-border: rgba(0, 0, 0, 0.12);
$options-menu-hover-color: $hover-filter-gray;
$options-menu-selected-color: $accent-gray;
$background-light-gray: #f8f8f8;
$background-medium-gray: #f2f2f2;
$material-error-red: #f44336;
$tag-background-gray: #d1d0d9;

// colors
$primary-background: $white;
$page-background: $ui-light-gray;
$gfs-primary: $primary-red;
$button-primary-background: $primary-blue;
$button-primary-text: $white;
$button-secondary-background: $white;
$button-secondary-text: $primary-blue;
$button-gradient-blue: linear-gradient(to left, $secondary-blue, $primary-blue);
$button-destructive-text: $primary-red;
$dark-selected-action: $primary-blue;
$primary-warning: $accent-light-yellow;
$urgent-warning: $accent-orange;
$triple-dot-button-background: $white;
$action-hover-state-background: rgba(80, 80, 80, 0.1);
$secondary-hover-background: rgba(43, 90, 135, 0.1);
$action-hover-state-background-black: rgba(0, 0, 0, 0.05);
$category-header-hover: $gray;
$select-card-background: $blue-select;
$scroll-bar-color: $primary-light-gray;
$attribute-cell-background: $ui-light-gray;
$hover-icon-background: $ui-light-gray;
$drag-accent: $accent-medium-yellow;
$banner-message-background: #ffdb71;
$label-light-gray: #737373;

// gradients
$gradient-blue: $primary-blue to $secondary-blue;
$gradient-gray: $primary-dark-gray to $secondary-gray;
$search-bar-gradient: linear-gradient(to bottom, $white, $ui-light-gray);
$lists-header-gradient: linear-gradient(268deg, #686868, $primary-dark-gray);
$container-header-gradient: linear-gradient(
  262deg,
  $secondary-gray,
  $primary-dark-gray
);

// text colors
$dark-product-text: $primary-dark-gray;
$light-product-text: $secondary-gray;
$primary-action-color: $accent-teal;
$connectivity-header: $secondary-gray;
$tab-labels: $primary-dark-gray;
$card-title: $primary-dark-gray;
$card-description: $primary-dark-gray;
$secondary-header: $primary-dark-gray;
$dark-selected-text: $ui-light-gray;
$placeholder-color: $primary-light-gray;
$link-color: $primary-blue;
$error-text: $primary-red;
$menu-primary-action: $primary-blue;
$success-text: $accent-green;
$product-flag-text-red: $flag-red;
$product-flag-text-green: $flag-green;
$product-flag-text-yellow: $flag-yellow;
$product-flag-text-blue: $flag-blue;
$product-flag-text-light-green: $flag-light-green;
$heading-text: $secondary-black;

// border and separator colors
$primary-border: $primary-light-gray;
$urgent-border: $primary-light-yellow;
$header-border: $ui-light-gray;
$tab-active: $primary-red;
$secondary-header-border: $accent-gray;
$input-border: $primary-blue;
$submenu-background: $secondary-light-gray;
$destroy-border: $primary-red;
$destroy-border-accessibility: rgba($primary-red, 0.5);

// border and separator constants
$box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.05);
$box-shadow-top: 0 -1px 10px 0 rgba(0, 0, 0, 0.2);
$recipe-padding-bottom: 24px;
$no-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
  0 0 0 0 rgba(0, 0, 0, 0.12);
$customer-card-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
$thin-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.05);
$medium-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1),
  0 0 3px 0 rgba(0, 0, 0, 0.05);

// banner
$warning-banner-background: rgba($primary-red, 0.15);

// mobile colors
$mobile-result: $primary-dark-gray;
$mobile-header-border: $primary-light-gray;
$mobile-header-background: $ui-light-gray;
$mobile-sort-filter-background: $ui-light-gray;
$mobile-quantity-pillbox-background: $ui-light-gray;
$mobile-taxonomy-filter-background: $ui-lighter-gray;

// breakpoints
$screen-x-small: 300px;
$screen-small: 360px;
$screen-medium: 900px;
$screen-large: 1280px;
$screen-x-large: 1600px;

// font attributes
$light-weight: 300;
$regular-weight: 400;
$medium-weight: 500;
$bold-weight: 700;

// font sizes
$h1-desktop-font-size: 36px;
$h1-mobile-font-size: 28px;
$h2-desktop-font-size: 28px;
$h2-mobile-font-size: 24px;
$input-font-size: 16px; // minimum font size to avoid zooming on mobile
